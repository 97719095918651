import { createCookie, readCookie, eraseCookie } from './../helpers/cookies-helper';
const switcherID = 'js-select-loacalization'
const switcher = document.getElementById(switcherID)

if (switcher) {
    switcher.addEventListener('change', (e) => {
        let localization = switcher.value
        let cookie = readCookie('user_localization')
        if (cookie) {
            eraseCookie('eraseCookie')
            createCookie('user_localization', localization, 120)
        }
        else {
            createCookie('user_localization', localization, 120)
        }
        window.location.reload()
    })
}
